import './App.css';
import RotatableDraggable from './components/Draggable';

function App() {
  return (
    <div className="App">
      <RotatableDraggable >
        {[2,4,1]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[5,4,1]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[5,2,3]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[2,4,3]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[1,3,4]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[2,1,5]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[4,5,1]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[3,5]}
      </RotatableDraggable>
      <RotatableDraggable >
        {[2,3]}
      </RotatableDraggable>
    </div>
  );
}

export default App;
