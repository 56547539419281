import React, { useState } from "react";
import Draggable from "react-draggable";
import "./component.css";

const RotatableDraggable = (props) => {
    const [position, setPosition] = useState({x:0,y:0});
    const [rotation, setRotation] = useState(0);

    const handleRotate = () => {
        setRotation((prevRotation) => (prevRotation + 90) %360);
    };

    const createSpans = (list) => {
        return list.slice(0, 3).map((item) => (
          <span className="square">{item}</span>
        ));
    };


    return (
        <Draggable onDrag={(e,data) => setPosition({x:data.x,y:data.y})}>
            <div className="draggable" style={{
                rotate:`${rotation}deg`,
                transformOrigin: `${position.x + props.children.length*25 +props.children.length*1.6}px ${position.y + 27.4}px`
            }}
            onWheel={handleRotate}
            onDoubleClick={handleRotate}>
                {createSpans(props.children)}
            </div>
        </Draggable>
    )
}

export default RotatableDraggable;
